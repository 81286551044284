import { ChartSpec } from "./types";

export function chartHasDataframe(chart: ChartSpec): boolean {
  if (chart.type === "concat" || chart.type === "unsupported") {
    // unsupported
    return false;
  }
  return chart.layers.some((layer) => layer.dataFrame != null);
}

export function chartMissingAxes(chart: ChartSpec): string[] {
  if (
    isChartConfiguredEnoughForRender(chart) ||
    chart.type === "concat" ||
    chart.type === "unsupported" ||
    chart.layers?.length === 0 ||
    (chart.layers[0]?.series?.length ?? 0) === 0
  ) {
    return [];
  }
  const layer = chart.layers[0];
  const s = layer?.series[0];
  const result: string[] = [];
  if (s != null) {
    const [x, y] =
      s.type === "bar" && s.orientation === "horizontal"
        ? ["Y", "X"]
        : s.type === "pie"
          ? ["color", "size"]
          : ["X", "Y"];

    if (
      (s.type !== "pie" && layer?.xAxis?.dataFrameColumn == null) ||
      (s.type === "pie" && s.colorDataFrameColumn == null)
    ) {
      result.push(x);
    }
    if (s.type !== "histogram" && s.dataFrameColumns.length === 0) {
      result.push(y);
    }
  }
  return result;
}

export function isChartConfiguredEnoughForRender(chart: ChartSpec): boolean {
  if (chart.type === "concat" || chart.type === "unsupported") {
    // unsupported
    return false;
  }

  return chart.layers.some((layer) => {
    return (
      layer.dataFrame != null &&
      layer.series.some((s) => {
        if (s.type === "pie") {
          return (
            s.dataFrameColumns.length > 0 &&
            s.colorDataFrameColumn != null &&
            layer.xAxis.dataFrameColumn != null
          );
        } else if (s.type === "histogram") {
          return layer.xAxis.dataFrameColumn != null;
        }
        return (
          layer.xAxis.dataFrameColumn != null && s.dataFrameColumns.length > 0
        );
      })
    );
  });
}

export function isChartSuggestible(chart: ChartSpec): boolean {
  if (chart.type === "concat" || chart.type === "unsupported") {
    // unsupported
    return false;
  }

  return chart.layers.some((layer) => {
    return layer.dataFrame != null && layer.series.length === 0;
  });
}

export function chartIsMultiAxisOrMultiSeries(chart: ChartSpec): boolean {
  if (chart.type === "concat" || chart.type === "unsupported") {
    // unsupported
    return false;
  }

  return chart.layers.some((layer) => layer.series.length > 1);
}
