import { ChartAxisWithData, ChartSeries } from "./types";

/**
 * For multi-series, based on the combination of series types
 * we may need to have a shared tooltip layer across series
 * as independent tooltips could result in conflicting tooltip
 * hover/interaction behavior.
 */
export type ChartTooltipResolve = "shared" | "independent";

// For multi-series, we share tooltips across all series only when
// none of the series are grouped. When series are grouped (read:
// have colorDataFrameColumn value set), shared tooltips don't work
// well for different reasons.
// For line/area series, grouped series have pivoted data which make
// it impossible (as far as I know) to have shared tooltips as we
// cannot get all the data into a single table -- each pivoted series
// will have their own transformed datasets and these datsets cannot
// be combined with each other or the original unpivoted dataset.
// In order to have a single tooltip encoding that can render values
// for all series, all the data must be in a single dataset.
// For bar charts, grouped bars need to be hovered separately in order
// to get the values for each bar, so we don't combine it with other
// series tooltips.
// For scatterplots where numerous points are rendered on the chart,
// the only UX interaction that makes much sense for tooltips is to
// show the tooltip when hovered.
export function getChartTooltipResolve(
  series: readonly ChartSeries[],
  xAxis: ChartAxisWithData,
): ChartTooltipResolve {
  if (series.length > 1) {
    const hasScatterSeries = series.some((s) => s.type === "scatter");
    if (hasScatterSeries) {
      return "independent";
    }

    const hasGroupedSeries = series.some((s) => s.colorDataFrameColumn != null);
    if (hasGroupedSeries) {
      return "independent";
    }

    // this bit should stay in sync with ChartSeriesTranslator.getEncodings
    const hasDisaggregatingTooltip = series.some(
      (s) =>
        s.tooltip?.type === "manual" &&
        s.tooltip?.fields.filter(
          (f) =>
            ![
              xAxis.dataFrameColumn,
              s.colorDataFrameColumn,
              s.dataFrameColumns[0],
            ].includes(f.dataFrameColumn) && f.type !== "number",
        ).length > 0,
    );
    if (hasDisaggregatingTooltip) {
      return "independent";
    }

    return "shared";
  }
  return "independent";
}

export const CHART_SHARED_TOOLTIP_PARAM_NAME = "shared_pivot_hover_param";
