import { cloneDeep, uniqBy } from "lodash";

import type {
  CalcExpr,
  SelectExpr,
} from "../../../src/semantic-layer/generated/hex_sl_schema.js";
import { nullthrows } from "../../assertions.js";
import { ColumnFilter } from "../../display-table/filterTypes.js";
import { assertNever } from "../../errors.js";
import { COUNT_STAR_ARG } from "../../explore/exploreFieldUtils.js";
import { ExploreFieldType, ExploreSpec } from "../../explore/types.js";
import { addFiltersToHqlBuilder } from "../../filter/hql/filterHql.js";
import { addDetailFieldsToHqlBuilder } from "../../hql/detailFieldsHql.js";
import {
  HqlNextSpecBuilder,
  HqlSelectExprBuilder,
} from "../../hql/HqlNextSpecBuilder.js";
import { joinDatasetQueryPath } from "../../hql/hqlNextUtils.js";
import {
  HqlSpecBuilder,
  hqlCol,
  hqlFunction,
  hqlStr,
} from "../../hql/HqlSpecBuilder.js";
import {
  CalciteType,
  HqlAggregationFunction,
  HqlQueryColumn,
  HqlTruncUnit,
} from "../../hql/types.js";
import {
  ExploreCalcsDefinition,
  HEX_HIDDEN_PREFIX,
  addCalcReferencedParamsToHqlBuilder,
  getExploreFieldName,
} from "../../index.js";
import { notEmpty } from "../../notEmpty.js";
import { getDataframeName } from "../../sql/dataSourceTableConfig.js";
import { concatRespectingCase } from "../../utils/stringUtils.js";
import { typedObjectValues } from "../../utils/typedObjects.js";
import { aggregateAlwaysPossible } from "../chartConstants.js";
import { getChartSeriesValuesDataFrameColumns } from "../chartDataFrameUtils.js";
import { getChartTooltipResolve } from "../chartTooltipUtils.js";
import { isChartConfiguredEnoughForRender } from "../chartValidationUtils.js";
import {
  ChartAggregate,
  ChartAxisWithData,
  ChartBin,
  ChartDataType,
  ChartFacet,
  ChartLayer,
  ChartSeries,
  ChartSpec,
  ChartSqlColumnMappings,
  ChartTimeUnit,
} from "../types.js";

import { ChartHqlSpec, ChartHqlSpecs, ChartJoinSpec } from "./chartHqlSpecs.js";

export interface ChartSemanticColumnMetadata {
  name: string;
  columnType: ExploreFieldType;
  queryPath?: string[];
  dataType: CalciteType;
}

interface ChartSqlColumn {
  source:
    | "hfacet"
    | "vfacet"
    | "xAxis"
    | "series"
    | "colorCategorical"
    | "colorQuantitative"
    | "opacity"
    | "tooltip";
  name: string;
  nameWithoutQueryPath: string;
  dataType?: ChartDataType;
  semanticType: ExploreFieldType;
  semanticDataset?: string;
  aggregation?: ChartAggregate;
  timeUnit?: ChartTimeUnit;
  facetBin?: { maxBins: number };
  histogramBin?: ChartBin;
  isForcedAggregation?: boolean;
}

// eslint-disable-next-line max-lines-per-function
export function getChartHqlSpecs({
  calcs,
  chartSpec,
  columnsByDataframe,
  filters = [],
  hqlNextArgs,
}: {
  chartSpec: ChartSpec;
  /**
   * Only used by the old hql path.
   */
  columnsByDataframe?: Record<string, HqlQueryColumn[]>;
  filters?: ColumnFilter[];
  calcs?: ExploreCalcsDefinition;
  hqlNextArgs?: {
    baseDataset: string;
    columnMetadata?: Record<string, ChartSemanticColumnMetadata>;
    addDetailFields?: boolean;
    /**
     * This doesn't change the column names generated by the query, but it does
     * update the return `columnMappings` to map from the outputted column name
     * to the expected display table value (which includes aggs/trunc units)
     * rather than just the column name itself. This is necessary since the
     * chart output column names differ from the nested table/pivot column
     * names.
     */
    mapColumnNamesToTableDisplayNames?: boolean;
    exploreSpec?: ExploreSpec;
  };
}): ChartHqlSpecs {
  //TODO(EXPLORE) this function has gotten to be way too long
  const hqlSpecs: ChartHqlSpec[] = [];
  const joinSpecs: ChartJoinSpec[] = [];
  const columnNameMappings: ChartSqlColumnMappings = {};

  const {
    addDetailFields,
    baseDataset,
    columnMetadata,
    exploreSpec,
    mapColumnNamesToTableDisplayNames,
  } = hqlNextArgs ?? {
    baseDataset: undefined,
    columnMetadata: {},
    mapColumnNamesToTableDisplayNames: false,
    exploreSpec: undefined,
    addDetailFields: false,
  };

  if (
    chartSpec.type === "concat" ||
    chartSpec.type === "unsupported" ||
    !isChartConfiguredEnoughForRender(chartSpec)
  ) {
    return {
      hqlSpecs,
      joinSpecs,
      columnNameMappings,
      updatedChartSpec: chartSpec,
      seriesValuesDataframeColumns:
        getChartSeriesValuesDataFrameColumns(chartSpec),
    };
  }

  // clone spec as we may need to modify the spec later
  const updatedChartSpec = cloneDeep(chartSpec);
  const { facet, layers } = updatedChartSpec;

  // facets
  const facetColumns: ChartSqlColumn[] = [];
  if (facet != null) {
    for (const [source, facetDir] of Object.entries({
      vfacet: facet.facetVertical,
      hfacet: facet.facetHorizontal,
    })) {
      if (facetDir?.dataFrameColumn != null) {
        facetColumns.push({
          source: source === "vfacet" ? "vfacet" : "hfacet",
          name: facetDir.dataFrameColumn,
          nameWithoutQueryPath: getColumnNameWithoutQueryPath(
            facetDir.dataFrameColumn,
            columnMetadata,
          ),
          dataType: facetDir.type,
          semanticType: getSemanticType(
            facetDir.dataFrameColumn,
            columnMetadata,
          ),
          semanticDataset: getSemanticDataset(
            facetDir.dataFrameColumn,
            columnMetadata,
          ),
          timeUnit: facetDir.timeUnit,
          facetBin:
            facetDir.maxBins != null
              ? {
                  maxBins: facetDir.maxBins,
                }
              : undefined,
        });
      }
    }

    updateSpecColumnNamesInPlace(facetColumns, { type: "facet", value: facet });
  }

  // layers
  for (const layer of layers) {
    const { series: layerSeries, xAxis } = layer;
    const layerSpecs: { seriesSpecName: string; columns: ChartSqlColumn[] }[] =
      [];

    if (layer.dataFrame == null) {
      continue;
    }

    let xAxisColumn: ChartSqlColumn | undefined;

    for (const series of layerSeries) {
      if (xAxis.dataFrameColumn != null) {
        const forceAggregation = shouldForceXAxisAggregation(xAxis, series);
        const fallbackAggregation = forceAggregation ? "min" : undefined;

        xAxisColumn = {
          source: "xAxis",
          name: xAxis.dataFrameColumn,
          nameWithoutQueryPath: getColumnNameWithoutQueryPath(
            xAxis.dataFrameColumn,
            columnMetadata,
          ),
          dataType: xAxis.type,
          semanticType: getSemanticType(xAxis.dataFrameColumn, columnMetadata),
          semanticDataset: getSemanticDataset(
            xAxis.dataFrameColumn,
            columnMetadata,
          ),
          aggregation: xAxis.aggregate ?? fallbackAggregation,
          timeUnit: xAxis.timeUnit,
          isForcedAggregation: forceAggregation,
        };

        if (series.type === "histogram") {
          xAxisColumn.histogramBin = series.bin;
        }
      }

      const dataFrameColumns: ChartSqlColumn[] = series.dataFrameColumns.map(
        (column) => {
          return {
            source: "series",
            name: column,
            nameWithoutQueryPath: getColumnNameWithoutQueryPath(
              column,
              columnMetadata,
            ),
            semanticType: getSemanticType(column, columnMetadata),
            semanticDataset: getSemanticDataset(column, columnMetadata),
            dataType: series.axis.type,
            aggregation: series.axis.aggregate,
            timeUnit: series.axis.timeUnit,
          };
        },
      );

      const colorDataFrameColumn: ChartSqlColumn | null =
        series.colorDataFrameColumn != null
          ? {
              source: "colorCategorical",
              name: series.colorDataFrameColumn,
              nameWithoutQueryPath: getColumnNameWithoutQueryPath(
                series.colorDataFrameColumn,
                columnMetadata,
              ),
              dataType: "string",
              semanticType: getSemanticType(
                series.colorDataFrameColumn,
                columnMetadata,
              ),
              semanticDataset: getSemanticDataset(
                series.colorDataFrameColumn,
                columnMetadata,
              ),
            }
          : null;

      const colorDotDataFrameColumn: ChartSqlColumn | null =
        series.color.type === "dataframe" &&
        series.color.dataFrameColumn != null
          ? {
              source: "colorQuantitative",
              name: series.color.dataFrameColumn,
              nameWithoutQueryPath: getColumnNameWithoutQueryPath(
                series.color.dataFrameColumn,
                columnMetadata,
              ),
              dataType: series.color.dataType,
              semanticType: getSemanticType(
                series.color.dataFrameColumn,
                columnMetadata,
              ),
              semanticDataset: getSemanticDataset(
                series.color.dataFrameColumn,
                columnMetadata,
              ),
              aggregation: series.color.aggregate,
            }
          : null;

      const opacityColumn: ChartSqlColumn | null =
        series.opacity.type === "dataframe" &&
        series.opacity.dataFrameColumn != null
          ? {
              source: "opacity",
              name: series.opacity.dataFrameColumn,
              nameWithoutQueryPath: getColumnNameWithoutQueryPath(
                series.opacity.dataFrameColumn,
                columnMetadata,
              ),
              semanticType: getSemanticType(
                series.opacity.dataFrameColumn,
                columnMetadata,
              ),
              semanticDataset: getSemanticDataset(
                series.opacity.dataFrameColumn,
                columnMetadata,
              ),
              dataType: series.opacity.dataType,
            }
          : null;

      const tooltipColumns: ChartSqlColumn[] = [];
      if (
        series.tooltip?.type === "manual" ||
        series.tooltip?.type === "custom"
      ) {
        series.tooltip.fields.forEach((field) => {
          let aggregation = field.aggregate;
          let timeUnit = undefined;
          let histogramBin = undefined;

          if (series.tooltip?.type === "manual") {
            if (field.dataFrameColumn === xAxisColumn?.name) {
              aggregation = aggregation ?? xAxisColumn.aggregation;
              timeUnit = xAxisColumn.timeUnit;
              if (series.type === "histogram") {
                histogramBin = xAxisColumn.histogramBin;
              }
            } else if (field.dataFrameColumn === dataFrameColumns[0]?.name) {
              aggregation = aggregation ?? dataFrameColumns[0].aggregation;
            } else if (
              field.dataFrameColumn === colorDotDataFrameColumn?.name
            ) {
              aggregation = aggregation ?? colorDotDataFrameColumn.aggregation;
            } else {
              const yAggregate = dataFrameColumns[0]?.aggregation;
              aggregation =
                aggregation ??
                (field.type !== "number"
                  ? undefined
                  : // always use an aggregate for quantitative manual fields
                    // y.agg is a sensible default unless it's count
                    // count is always the same for all fields so it's not useful
                    yAggregate !== "count"
                    ? yAggregate
                    : "sum");
            }
          }
          tooltipColumns.push({
            source: "tooltip",
            name: field.dataFrameColumn,
            nameWithoutQueryPath: getColumnNameWithoutQueryPath(
              field.dataFrameColumn,
              columnMetadata,
            ),
            dataType:
              aggregation != null && !aggregateAlwaysPossible(aggregation)
                ? "number"
                : field.type,
            semanticType: getSemanticType(
              field.dataFrameColumn,
              columnMetadata,
            ),
            semanticDataset: getSemanticDataset(
              field.dataFrameColumn,
              columnMetadata,
            ),
            aggregation,
            timeUnit,
            histogramBin,
          });
        });
      }

      const seriesColumns = [
        ...dataFrameColumns,
        colorDataFrameColumn,
        colorDotDataFrameColumn,
        opacityColumn,
        ...tooltipColumns,
      ].filter(notEmpty);
      const collected = [...facetColumns, xAxisColumn, ...seriesColumns].filter(
        notEmpty,
      );

      const dataframeName = getDataframeName(layer.dataFrame);

      // builder for HQL
      const builder = new HqlSpecBuilder(
        dataframeName,
        columnsByDataframe?.[dataframeName] ?? [],
      );

      // builder for HQL-next
      const builder2 = new HqlNextSpecBuilder(baseDataset ?? dataframeName);

      // Add projections -- all used columns while taking into account
      // date casting and truncation.
      // Column names are generated using the column's usage minus
      // the aggregation to avoid conflicts with names of aggregated columns
      const projectionBuilder = builder.addProjections(false);

      const selectsByName: Record<
        string,
        {
          expr: SelectExpr;
          finalColumnName: string;
        }
      > = {};

      for (const column of uniqBy(collected, getPreaggColumnName)) {
        if (
          column.semanticType === "MEASURE" ||
          column.nameWithoutQueryPath === COUNT_STAR_ARG
        ) {
          // cannot add measures to projections as they're not scalar
          continue;
        }

        const selectExprBuilder = new HqlSelectExprBuilder(
          column.nameWithoutQueryPath,
          getCalciteType(column, columnMetadata),
        );

        const timeUnit = toSqlTruncUnit(column.timeUnit);
        const preaggColumnName = getPreaggColumnName(column);

        if (column.dataType === "datetime") {
          const datecastColumnName = `datecast_${preaggColumnName}`;
          projectionBuilder.datecast({
            column: column.nameWithoutQueryPath,
            as: datecastColumnName,
          });
          selectExprBuilder.datecast();

          if (timeUnit != null) {
            projectionBuilder.datetrunc({
              column: datecastColumnName,
              unit: timeUnit,
              as: preaggColumnName,
            });
            selectExprBuilder.datetrunc(timeUnit);
          } else {
            projectionBuilder.column({
              column: datecastColumnName,
              as: preaggColumnName,
            });
          }
        } else if (column.dataType === "number") {
          projectionBuilder.numbercast({
            column: column.nameWithoutQueryPath,
            as: preaggColumnName,
          });
          selectExprBuilder.numbercast();
        } else if (column.dataType === "string" || column.dataType == null) {
          projectionBuilder.column({
            column: column.nameWithoutQueryPath,
            as: preaggColumnName,
          });
        } else {
          assertNever(column.dataType, column.dataType);
        }

        if (column.histogramBin?.type === "dataFrameColumn") {
          const finalColumnName = getSqlColumnName(column);
          projectionBuilder.column({
            column: preaggColumnName,
            as: finalColumnName,
          });
        }

        selectsByName[preaggColumnName] = {
          expr: selectExprBuilder.build({
            as: preaggColumnName,
            dataset: column.semanticDataset,
          }),
          finalColumnName: getSqlColumnName(column),
        };
      }

      // Heads-up: projections can still be added after this line!
      // They accumulate at this point in the build.
      // Transforms added after this line e.g. bin transforms,
      // appear after all projections, but before the aggregations,
      // as currently desired.
      projectionBuilder.buildProject();

      // Add aggregations
      const dedupedColumns = uniqBy(collected, getSqlColumnName);
      const aggregationBuilder = builder.addAggregations();
      const aggregationBuilder2 = builder2.addAggregations();

      for (const column of dedupedColumns) {
        const aggregation = toSqlAggregation(column.aggregation);

        const preaggColumnName = getPreaggColumnName(column);
        const finalColumnName = getSqlColumnName(column);
        columnNameMappings[finalColumnName] = getColumnMappingValue(
          column,
          mapColumnNamesToTableDisplayNames,
          columnMetadata,
        );

        // handle special case count(*) - this can either be our special "count
        // star" measure (which exists in the explore and chart cell), or a
        // "count star" aggregation tied to a specific column (legacy, only on
        // chart cells.)
        const isCountStarMeasure =
          column.nameWithoutQueryPath === COUNT_STAR_ARG;

        const isCountStarOnColumn =
          aggregation === HqlAggregationFunction.Count &&
          column.aggregation === "count";

        if (isCountStarMeasure || isCountStarOnColumn) {
          aggregationBuilder2.aggregation({
            expr: { fun: "count", args: [] },
            dataset: column.semanticDataset,
            alias: finalColumnName,
          });
          aggregationBuilder.aggregation({
            expr: hqlFunction("Count", []),
            as: finalColumnName,
          });
          continue;
        }

        if (column.semanticType === "MEASURE") {
          aggregationBuilder2.aggregation({
            measure: column.nameWithoutQueryPath,
            alias: finalColumnName,
            dataset: column.semanticDataset,
          });

          continue;
        }

        const select = nullthrows(
          selectsByName[preaggColumnName],
          `selectExpr should exist for ${preaggColumnName}`,
        );

        if (aggregation != null) {
          aggregationBuilder.aggregation({
            expr: hqlFunction(aggregation, [hqlCol(preaggColumnName)]),
            as: finalColumnName,
          });

          aggregationBuilder2.aggregation({
            expr: {
              fun: aggregation.toLowerCase() as Lowercase<HqlAggregationFunction>,
              args: [select.expr.expr as CalcExpr],
            },
            dataset: select.expr.dataset,
            alias: finalColumnName,
          });
        } else if (column.facetBin != null) {
          // for the purposes of facets for now
          builder.bin({
            maxbins: column.facetBin.maxBins,
            column: preaggColumnName,
            as_prefix: finalColumnName,
          });

          const binStartColumn = concatRespectingCase(
            finalColumnName,
            "_bin_start",
          );
          const binStepColumn = concatRespectingCase(finalColumnName, "_step");
          aggregationBuilder.groupby(binStartColumn);
          aggregationBuilder.groupby(binStepColumn);
          columnNameMappings[binStartColumn] = binStartColumn;
          columnNameMappings[binStepColumn] = binStepColumn;

          aggregationBuilder2.groupby({
            alias: finalColumnName,
            binning: {
              maxbins: column.facetBin.maxBins,
            },
            dataset: select.expr.dataset,
            expr: select.expr.expr as CalcExpr,
          });
        } else if (column.histogramBin != null) {
          // dataFrameColumn histograms are binned already
          if (column.histogramBin.type !== "dataFrameColumn") {
            builder.bin({
              maxbins:
                column.histogramBin.type === "count"
                  ? column.histogramBin.value
                  : undefined,
              step:
                column.histogramBin.type === "size"
                  ? column.histogramBin.value
                  : undefined,
              column: preaggColumnName,
              as_prefix: finalColumnName,
            });

            aggregationBuilder2.groupby({
              alias: finalColumnName,
              binning: {
                maxbins:
                  column.histogramBin.type === "count"
                    ? column.histogramBin.value
                    : undefined,
                step:
                  column.histogramBin.type === "size"
                    ? column.histogramBin.value
                    : undefined,
              },
              dataset: select.expr.dataset,
              expr: select.expr.expr as CalcExpr,
            });

            const aggColumnName = concatRespectingCase(
              finalColumnName,
              "_count",
            );
            aggregationBuilder.aggregation({
              expr: hqlFunction("Count", []),
              as: aggColumnName,
            });
            aggregationBuilder2.aggregation({
              expr: { fun: "count", args: [] },
              alias: aggColumnName,
            });

            const binStartColumn = finalColumnName + "_bin_start";
            const binStopColumn = finalColumnName + "_bin_stop";
            aggregationBuilder.groupby(binStartColumn);
            aggregationBuilder.groupby(binStopColumn);

            columnNameMappings[aggColumnName] = concatRespectingCase(
              column.name,
              "_count",
            );
            columnNameMappings[binStartColumn] = concatRespectingCase(
              column.name,
              "_bin_start",
            );
            columnNameMappings[binStopColumn] = concatRespectingCase(
              column.name,
              "_bin_stop",
            );
            // for hql-next which have different bin naming
            columnNameMappings[finalColumnName] = column.name;
            columnNameMappings[finalColumnName + "_end"] = concatRespectingCase(
              column.name,
              "_bin_end",
            );
            columnNameMappings[finalColumnName + "_step"] =
              concatRespectingCase(column.name, "_step");
          } else {
            aggregationBuilder.aggregation({
              expr: hqlFunction("Count", []),
              as: finalColumnName + "_count",
            });
            aggregationBuilder2.aggregation({
              expr: { fun: "count", args: [] },
              alias: finalColumnName + "_count",
            });

            aggregationBuilder.groupby(finalColumnName);
            aggregationBuilder2.groupby({
              ...select.expr,
              alias: finalColumnName,
            });
          }
        } else {
          // in here preagg column name == final column name
          if (column.dataType === "string") {
            projectionBuilder.projection({
              expr: hqlFunction("Coalesce", [
                hqlFunction("ToText", [hqlCol(preaggColumnName)]),
                hqlStr("null"),
              ]),
              as: finalColumnName,
            });

            select.expr.expr = {
              fun: "coalesce",
              args: [
                {
                  fun: "totext",
                  args: [select.expr.expr as CalcExpr],
                },
                { str: "null" },
              ],
            };
          } else {
            projectionBuilder.column({
              column: preaggColumnName,
              as: finalColumnName,
            });
          }

          aggregationBuilder.groupby(finalColumnName);
          aggregationBuilder2.groupby({
            ...select.expr,
            alias: finalColumnName,
          });
        }
      }

      aggregationBuilder.buildAggregate();

      if (calcs) {
        addCalcReferencedParamsToHqlBuilder({
          builder: aggregationBuilder2,
          calcs,
        });
      }
      // build the agg query and see if there were any aggregations.
      // if not, it means that the chart has no aggregations and we
      // should select the expressions directly for an unagg'd chart
      if (filters && filters.length > 0) {
        addFiltersToHqlBuilder({
          builder: aggregationBuilder2,
          filters,
        });
      }

      const shouldAddDetailFields =
        addDetailFields &&
        hqlNextArgs &&
        exploreSpec &&
        exploreSpec.details.fields.length > 0 &&
        exploreSpec.details.enabled;
      if (shouldAddDetailFields) {
        addDetailFieldsToHqlBuilder({
          builder: aggregationBuilder2,
          fields: exploreSpec.details.fields,
          spec: exploreSpec,
        });
      }

      aggregationBuilder2.buildAggregation({ skipIfNoAggregations: true });
      let hqlNextQuery = builder2.build();
      if (hqlNextQuery.query.transforms.length === 0) {
        const selectionBuilder2 = builder2.addSelections(false);
        typedObjectValues(selectsByName).forEach(
          ({ expr, finalColumnName }) => {
            selectionBuilder2.select({
              ...expr,
              alias: finalColumnName,
            });
          },
        );

        if (shouldAddDetailFields) {
          addDetailFieldsToHqlBuilder({
            builder: selectionBuilder2,
            fields: exploreSpec.details.fields,
            spec: exploreSpec,
          });
        }

        if (filters && filters.length > 0) {
          addFiltersToHqlBuilder({
            builder: selectionBuilder2,
            filters,
          });
        }
        if (calcs) {
          addCalcReferencedParamsToHqlBuilder({
            builder: selectionBuilder2,
            calcs,
          });
        }

        selectionBuilder2.buildSelection();
        hqlNextQuery = builder2.build();
      }

      const newDataFrameName = `${HEX_HIDDEN_PREFIX}${dataframeName}__${series.id.slice(0, 4)}`;
      hqlSpecs.push({
        seriesId: series.id,
        name: newDataFrameName,
        spec: builder.build(),
        nextSpec: hqlNextQuery,
      });
      layerSpecs.push({
        seriesSpecName: newDataFrameName,
        columns: dedupedColumns,
      });

      series.dataFrame = newDataFrameName;
      // update spec dataframe and column names in case they've changed
      updateSpecColumnNamesInPlace(seriesColumns, {
        type: "series",
        value: series,
      });
    }

    if (xAxisColumn != null) {
      updateSpecColumnNamesInPlace([xAxisColumn], {
        type: "layer",
        value: layer,
      });
    }

    if (
      layerSpecs.length === 1 ||
      // if there are any forced aggregations on the x-axis we cannot join queries
      // as the number of rows/values for x-axis columns will be different
      layerSpecs.some((ls) =>
        ls.columns.some(
          (c) => c.source === "xAxis" && c.isForcedAggregation === true,
        ),
      ) ||
      getChartTooltipResolve(layerSeries, xAxis) === "independent"
    ) {
      layer.dataFrame =
        // find the non-force-aggregated series spec name
        layerSpecs.find((ls) =>
          ls.columns.every(
            (c) => c.source !== "xAxis" || c.isForcedAggregation !== true,
          ),
        )?.seriesSpecName ?? layerSpecs[0]?.seriesSpecName;
    } else if (layerSpecs.length > 1 && xAxisColumn != null) {
      const joinTableName = `${layer.dataFrame}__${layer.id.slice(0, 4)}`;
      layer.dataFrame = joinTableName;

      const columns = uniqBy(
        layerSpecs.flatMap((s) => {
          return s.columns.map((column) => ({
            seriesSpecName: s.seriesSpecName,
            column: getSqlColumnName(column),
          }));
        }),
        (c) => c.column,
      ).map((c) => `${c.seriesSpecName}.${c.column} as ${c.column}`);

      joinSpecs.push({
        name: joinTableName,
        columns,
        tableNames: layerSpecs.map((s) => s.seriesSpecName),
        joinColumn: getSqlColumnName(xAxisColumn),
      });
    }
  }

  // if no specs were generated, there are no queries to run and
  // we should keep the unchanged original chart spec
  if (hqlSpecs.length === 0) {
    return {
      hqlSpecs,
      joinSpecs: [],
      columnNameMappings: {},
      updatedChartSpec: chartSpec,
      seriesValuesDataframeColumns:
        getChartSeriesValuesDataFrameColumns(chartSpec),
    };
  }

  // EXP-342: can we detect similar queries and optimize to reduce number of queries to run?
  return {
    hqlSpecs,
    joinSpecs,
    columnNameMappings,
    updatedChartSpec,
    seriesValuesDataframeColumns:
      getChartSeriesValuesDataFrameColumns(updatedChartSpec),
  };
}

function updateSpecColumnNamesInPlace(
  columns: ChartSqlColumn[],
  spec:
    | {
        type: "facet";
        value: ChartFacet;
      }
    | {
        type: "layer";
        value: ChartLayer;
      }
    | {
        type: "series";
        value: ChartSeries;
      },
): void {
  for (const column of columns) {
    const newColumnName = getSqlColumnName(column);

    if (spec.type === "facet") {
      switch (column.source) {
        case "vfacet":
          if (spec.value.facetVertical != null) {
            spec.value.facetVertical.dataFrameColumn = newColumnName;
          }
          break;
        case "hfacet":
          if (spec.value.facetHorizontal != null) {
            spec.value.facetHorizontal.dataFrameColumn = newColumnName;
          }
          break;
        case "xAxis":
        case "series":
        case "colorCategorical":
        case "colorQuantitative":
        case "opacity":
        case "tooltip":
          break;
        default:
          assertNever(column.source, column.source);
      }
    } else if (spec.type === "layer") {
      switch (column.source) {
        case "xAxis":
          if (spec.value.xAxis != null) {
            spec.value.xAxis.dataFrameColumn = newColumnName;
          }
          break;
        case "series":
        case "colorCategorical":
        case "colorQuantitative":
        case "opacity":
        case "tooltip":
        case "vfacet":
        case "hfacet":
          break;
        default:
          assertNever(column.source, column.source);
      }
    } else if (spec.type === "series") {
      switch (column.source) {
        case "series":
          spec.value.dataFrameColumns = spec.value.dataFrameColumns.map(
            (oldColumn) => {
              if (oldColumn === column.name) {
                return newColumnName;
              } else {
                return oldColumn;
              }
            },
          );
          break;
        case "colorCategorical":
          spec.value.colorDataFrameColumn = newColumnName;
          break;
        case "colorQuantitative":
          if (spec.value.color.type === "dataframe") {
            spec.value.color.dataFrameColumn = newColumnName;
          }
          break;
        case "opacity":
          if (spec.value.opacity.type === "dataframe") {
            spec.value.opacity.dataFrameColumn = newColumnName;
          }
          break;
        case "tooltip":
          if (
            spec.value.tooltip?.type === "manual" ||
            spec.value.tooltip?.type === "custom"
          ) {
            spec.value.tooltip.fields = spec.value.tooltip.fields.map(
              (field) => {
                if (field.dataFrameColumn === column.name) {
                  return {
                    ...field,
                    dataFrameColumn: newColumnName,
                  };
                } else {
                  return field;
                }
              },
            );
          }
          break;
        case "xAxis":
        case "vfacet":
        case "hfacet":
          break;
        default:
          assertNever(column.source, column.source);
      }
    } else {
      assertNever(spec, spec);
    }
  }
}
function getColumnMappingValue(
  column: ChartSqlColumn,
  mapColumnNamesToTableDisplayNames: boolean | undefined,
  columnMetadata: Record<string, ChartSemanticColumnMetadata> | undefined,
): string {
  if (!mapColumnNamesToTableDisplayNames || columnMetadata == null) {
    return column.name;
  } else {
    return getExploreFieldName({
      value: column.nameWithoutQueryPath,
      aggregation: toSqlAggregation(column.aggregation) ?? undefined,
      truncUnit: toSqlTruncUnit(column.timeUnit) ?? undefined,
      queryPath: columnMetadata[column.name]?.queryPath ?? [],
      fieldType:
        columnMetadata[column.name]?.columnType ?? ExploreFieldType.COLUMN,
    });
  }
}

// In the absence of y-axis and aggregate, force an x-aggregate to
// avoid bringing in too-many-rows.
// This needs to kep in sync with series translators.
function shouldForceXAxisAggregation(
  xAxis: ChartAxisWithData,
  series: ChartSeries,
): boolean {
  return (
    (series.type === "area" ||
      series.type === "line" ||
      series.type === "bar") &&
    xAxis.aggregate == null &&
    series.dataFrameColumns.length === 0
  );
}

function getPreaggColumnName(column: ChartSqlColumn): string {
  return (
    getSqlColumnName({
      ...column,
      facetBin: undefined,
      histogramBin: undefined,
      aggregation: undefined,
    }) + "_preagg"
  );
}

function getCalciteType(
  column: ChartSqlColumn,
  columnMetadata: Record<string, ChartSemanticColumnMetadata> | undefined,
): CalciteType | undefined {
  return columnMetadata?.[column.name]?.dataType;
}

function getSemanticType(
  columnName: string,
  columnMetadata: Record<string, ChartSemanticColumnMetadata> | undefined,
): ExploreFieldType {
  return columnMetadata?.[columnName]?.columnType ?? "COLUMN";
}

function getSemanticDataset(
  columnName: string,
  columnMetadata: Record<string, ChartSemanticColumnMetadata> | undefined,
): string | undefined {
  if (columnMetadata == null) {
    return;
  }

  const metadata = columnMetadata[columnName];
  if (metadata?.queryPath == null || metadata.queryPath.length === 0) {
    return;
  }
  return joinDatasetQueryPath(metadata.queryPath);
}

function getColumnNameWithoutQueryPath(
  columnName: string,
  columnMetadata: Record<string, ChartSemanticColumnMetadata> | undefined,
): string {
  if (columnMetadata == null) {
    return columnName;
  }

  const metadata = columnMetadata[columnName];
  return metadata?.name ?? columnName;
}

// exported for testing
export function getSqlColumnName(column: ChartSqlColumn): string {
  let name = column.name;
  if (column.dataType != null) {
    name = `${column.dataType}_${name}`;
  }
  // forced aggregations are aggregations that aren't added by the user
  // but by the system automatically, so avoid having it affect the column name
  // to avoid issues (like multiple series using different x-axis columns)
  if (column.aggregation != null && column.isForcedAggregation !== true) {
    name = `${column.aggregation}_${name}`;
  }
  if (column.timeUnit != null) {
    name = `${column.timeUnit}_${name}`;
  }
  if (column.facetBin != null) {
    name = `facet_maxbins_${column.facetBin.maxBins}_${name}`;
  }
  if (column.histogramBin != null) {
    if (column.histogramBin.type === "count") {
      name = `hist_maxbins_${column.histogramBin.value ?? ""}_${name}`;
    } else if (column.histogramBin.type === "size") {
      name = `hist_step_${column.histogramBin.value ?? ""}_${name}`;
    } else if (column.histogramBin.type === "dataFrameColumn") {
      name = `hist_column_${name}`;
    }
  }

  // remove special chars and append a hash to avoid collisions. We lowercase
  // the name because not all dialects are case sensitive.
  return `col_${name.toLowerCase().replace(/[^a-zA-Z0-9_]/g, "")}_${hashCode(name)}`;
}

export function toSqlAggregation(
  aggregation?: ChartAggregate,
): HqlAggregationFunction | null {
  if (aggregation == null) {
    return null;
  }

  switch (aggregation) {
    case "count":
      // this is later interpreted as count(*)
      return HqlAggregationFunction.Count;
    case "valid":
      return HqlAggregationFunction.Count;
    case "distinct":
      return HqlAggregationFunction.CountDistinct;
    case "sum":
      return HqlAggregationFunction.Sum;
    case "average":
    case "mean":
      return HqlAggregationFunction.Avg;
    case "min":
      return HqlAggregationFunction.Min;
    case "max":
      return HqlAggregationFunction.Max;
    case "median":
      return HqlAggregationFunction.Median;
    case "stdev":
      return HqlAggregationFunction.StdDev;
    case "stdevp":
      return HqlAggregationFunction.StdDevPop;
    case "variance":
      return HqlAggregationFunction.Variance;
    case "variancep":
      return HqlAggregationFunction.VariancePop;
    case "stderr":
      //TODO not implemented in HQL
      return null;
    case "ci0":
    case "ci1":
    case "missing":
    case "product":
    case "q1":
    case "q3":
    case "values":
      // not exposed in the chart UI but valid Vega
      return null;
    default:
      assertNever(aggregation, aggregation);
  }
}

export function toSqlTruncUnit(timeUnit?: ChartTimeUnit): HqlTruncUnit | null {
  if (timeUnit == null) {
    return null;
  }

  switch (timeUnit) {
    case "year":
      return "year";
    case "yearquarter":
      return "quarter";
    case "yearmonth":
      return "month";
    case "yearweek":
      return "week";
    case "yearmonthdate":
      return "day";
    case "yearmonthdatehours":
      return "hour";
    case "yearmonthdatehoursminutes":
      return "minute";
    case "yearmonthdatehoursminutesseconds":
      return "second";
    case "day":
      return "dayofweek";
    default:
      // eslint-disable-next-line no-console -- helpful for debugging
      console.debug("Unsupported sql gen time unit", timeUnit);
      return null;
  }
}

// hashcode logic mostly from https://stackoverflow.com/a/7616484
function hashCode(s: string): string {
  let hash = 0,
    i,
    chr;
  if (s.length === 0) return `${hash}`;
  for (i = 0; i < s.length; i++) {
    chr = s.charCodeAt(i);
    hash = (hash << 5) - hash + chr;
    hash |= 0; // Convert to 32bit integer
  }
  return Math.abs(hash).toString(36).slice(0, 6);
}
